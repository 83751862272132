import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { scroller } from 'react-scroll';

// component
const FormBoxWrap = dynamic(() => import('@/components/shared/formBoxWrap'), {
	ssr: false,
});

// material
import { Button } from '@mui/material';

// actions
import {
	goToExternalRoute,
	goToRoute,
	isExternalUrl,
} from '@/store/actions/navigation';

// utils
import * as eventActions from '@/utils/analytics';
import useFormBoxProps from '@/utils/hooks/use-form-box-props';
import useUserModal from '@/utils/hooks/use-login-modal';

// styles
import useStyles, { ctaButtonStyleWrapper } from './style';
const StyledCTAButton = ctaButtonStyleWrapper(Button);

export default function CTAButton({ label, link, openFormModal, ...props }) {
	const { classes, cx } = useStyles(props);

	const dispatch = useDispatch();
	const openLoginModal = useUserModal();
	const formBoxProps = useFormBoxProps(props);

	const [formOpen, setFormOpen] = React.useState(false);

	const trackEvent = (eventName, eventData = {}) => {
		eventActions.trackEvent(eventName, {
			...props.analyticsProps,
			eventLabel: label,
			eventAction: 'CLICKED',
			...eventData,
		});
	};

	const handleScrollToForm = () => {
		if (!props.formId) return;

		scroller.scrollTo(props.formId, {
			offset: -80,
		});
	};

	const handleFormClose = () => {
		setFormOpen(false);
	};

	const handleFormOpen = () => {
		if (!formBoxProps) return;

		trackEvent('FORM_MODAL_SHOWN', {
			eventCategory: 'USER_EVENT',
			isFormModal: true,
			formTitle: formBoxProps.title,
		});

		setFormOpen(true);
	};

	const openLogInModal = () => {
		trackEvent('CTA_BUTTON_CLICKED', {
			eventCategory: 'USER_EVENT',
		});

		openLoginModal('login');
	};

	const navigateToLink = () => {
		trackEvent('CTA_BUTTON_CLICKED', {
			eventCategory: 'NAVIGATION',
		});

		if (isExternalUrl(link)) {
			goToExternalRoute(link);
		} else {
			dispatch(goToRoute(link));
		}
	};

	const handleClick = () => {
		if (props.scrollToForm) {
			handleScrollToForm();
		} else if (props.openLoginModal) {
			openLogInModal();
		} else if (link) {
			navigateToLink();
		} else if (openFormModal) {
			handleFormOpen();
		}
	};

	const ButtonComponent = props.styledButton ? StyledCTAButton : Button;

	return (
		<>
			<ButtonComponent
				variant="contained"
				color={props.color || 'primary'}
				fullWidth={props.fullWidth}
				className={cx(props.className || '', classes.button)}
				onClick={handleClick}
			>
				{label}
			</ButtonComponent>

			{openFormModal && formBoxProps && (
				<FormBoxWrap
					{...formBoxProps}
					fullWidthSubmitButton={true}
					isModal={true}
					open={formOpen}
					handleClose={handleFormClose}
					analyticsProps={props.analyticsProps}
				/>
			)}
		</>
	);
}

CTAButton.propTypes = {
	pageSlug: PropTypes.string,
	label: PropTypes.string.isRequired,
	fullWidth: PropTypes.bool,
	className: PropTypes.string,
	link: PropTypes.string,
	openLoginModal: PropTypes.bool,
	openFormModal: PropTypes.bool,
	scrollToForm: PropTypes.bool,
	shadow: PropTypes.bool,
	styledButton: PropTypes.bool,
	color: PropTypes.string,
	analyticsProps: PropTypes.object,
	// form
	form: PropTypes.object,
	formId: PropTypes.string,
	useOtpFlowForForm: PropTypes.bool,
	paymentDetail: PropTypes.object,
	formPageConstants: PropTypes.object,
	formPageConfig: PropTypes.object,
};

CTAButton.defaultProps = {
	openLoginModal: false,
	openFormModal: false,
	scrollToForm: false,
	fullWidth: false,
	useOtpFlowForForm: false,
	shadow: true,
	styledButton: true,
	analyticsProps: {},
};
