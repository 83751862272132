export const listTypes = {
	v1: 'event',
	v2: 'blog',
	v3: 'other',
	v4: 'webstory',
};

export const textValues = {
	week: 'week',
	month: 'month',
	all: 'all',
	thisWeek: 'This Week',
	thisMonth: 'This Month',
	allEvents: 'All Events',
	noData: 'No data found',
	noUpcomingEvents: 'No upcoming events',
	noBlogs: 'No blogs available',
	linkText: 'View All',
};
