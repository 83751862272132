import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	titleSection: {
		marginBottom: theme.spacing(2),
	},
	tagSection: {
		marginBottom: theme.spacing(7),
		[theme.breakpoints.down('sm')]: {
			marginBottom: theme.spacing(4),
		},
	},
	loginCTAWrap: {
		marginBottom: theme.spacing(10),
		[theme.breakpoints.down('sm')]: {
			marginBottom: theme.spacing(4),
		},
	},
}));

export default useStyles;
