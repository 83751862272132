// components
import ImageComponent from '@/components/widgets/image';
import Markdown from '@/components/widgets/markdown';
// material
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// styles
import useStyles from './style';

export default function ListCard({ image, description, ...props }) {
	const { classes } = useStyles(props);
	const { isMobile, isDesktop } = useSelector((state) => state.common);

	const contentLength = isMobile ? 80 : isDesktop ? 250 : 150;
	let contentText = description;
	if (contentText && contentText.length > contentLength) {
		contentText = contentText.slice(0, contentLength) + '...';
	}

	return (
		<Box
			className={isMobile ? classes.mobileListCardWrap : classes.listCardWrap}
		>
			<Box className={isMobile ? classes.mobileImageWrap : classes.imageWrap}>
				{image && image.url && (
					<ImageComponent
						src={image.url}
						alt={image.alternativeText}
						quality="20%"
						layout="fill"
						objectFit="cover"
					/>
				)}
			</Box>
			<Box className={isMobile ? classes.mobileCardBody : classes.cardBody}>
				<Typography component="h6" className={classes.title}>
					{props.title}
				</Typography>
				<Box>
					<Markdown className={isMobile ? classes.mobileContent : ''}>
						{contentText}
					</Markdown>
				</Box>
			</Box>
		</Box>
	);
}

ListCard.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	image: PropTypes.object,
};

ListCard.defaultProps = {
	image: null,
};
