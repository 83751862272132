import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	dayFilterWrap: {
		display: 'flex',
		gap: theme.spacing(2),
	},
	dayFilter: {
		color: theme.palette.common.textLightGrey,
		fontWeight: theme.typography.fontWeightSemiBold,
		cursor: 'pointer',
	},
	activeFilter: {
		color: theme.palette.primary.main,
	},
	filterSection: {
		marginBottom: theme.spacing(4),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(4, 0),
		},
	},
	listBodySection: {
		margin: theme.spacing(2, 0),
	},
	linkAndPaginateRow: {
		marginTop: theme.spacing(4),
	},
	textCenter: {
		textAlign: 'center',
	},
	linkWrap: {
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
		},
	},
	link: {
		color: theme.palette.primary.main,
	},
	spaceBetween: {
		display: 'flex',
		justifyContent: 'space-between',
	},
}));

export default useStyles;
