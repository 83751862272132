import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// components
import CTAButton from '@/components/widgets/ctaButton';

// Material
import { Stars } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

// Styles

import useStyles from './style';

export default function LoginCTA({ ctaButton, ...props }) {
	const { classes, cx } = useStyles();
	const { isMobile } = useSelector((state) => state.common);

	const title = isMobile ? props.mobileTitle : props.title;
	const subTitle = isMobile ? props.mobileSubTitle : props.subTitle;

	return (
		<Box className={classes.LoginCTAWrap}>
			<Box className={classes.iconAndTextSection}>
				{!isMobile && (
					<Box className={classes.iconWrap}>
						<Box className={classes.whiteBackground}></Box>
						<Stars
							className={classes.icon}
							style={{ height: '100%', width: '100%' }}
						/>
					</Box>
				)}
				<Box className={isMobile && classes.textCenter}>
					<Typography component="h2">
						{props.highlightTitle && (
							<Typography
								className={cx(classes.title, classes.addSpaceAfter)}
								color="primary"
								component="span"
							>
								{props.highlightTitle}
							</Typography>
						)}
						<Typography className={classes.title} component="span">
							{title}
						</Typography>
					</Typography>
					{subTitle && (
						<Typography variant="body1" className={classes.subTitle}>
							{subTitle}
						</Typography>
					)}
				</Box>
			</Box>
			<Box className={classes.buttonWrap}>
				<CTAButton
					pageSlug={props.pageSlug}
					className={classes.ctaButton}
					fullWidth={true}
					label={ctaButton.label}
					link={ctaButton.link}
					form={props.form}
					formId={props.formId}
					openLoginModal={ctaButton.openLoginModal}
					openFormModal={ctaButton.openFormModal}
					scrollToForm={ctaButton.scrollToForm}
					formPageConstants={props.formPageConstants}
					formPageConfig={props.formPageConfig}
					useOtpFlowForForm={props.useOtpFlowForForm}
					paymentDetail={props.paymentDetail}
					analyticsProps={props.analyticsProps}
					shadow={ctaButton.shadow}
				/>
			</Box>
		</Box>
	);
}

LoginCTA.propTypes = {
	pageSlug: PropTypes.string,
	highlightTitle: PropTypes.string,
	title: PropTypes.string.isRequired,
	subTitle: PropTypes.string,
	mobileTitle: PropTypes.string.isRequired,
	mobileSubTitle: PropTypes.string,
	ctaButton: PropTypes.object.isRequired,
	analyticsProps: PropTypes.object,
	// form
	form: PropTypes.object,
	formId: PropTypes.string,
	useOtpFlowForForm: PropTypes.bool,
	paymentDetail: PropTypes.object,
	formPageConstants: PropTypes.object,
	formPageConfig: PropTypes.object,
};

LoginCTA.defaultProps = {
	buttonText: 'Start Now',
	analyticsProps: {},
};
