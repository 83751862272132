// material
import { Box, CircularProgress, Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';

// styles
import useStyles from './style';

export default function LoaderCard(props) {
	const { classes } = useStyles(props);
	const isMobile = useSelector((state) => state.common.isMobile);
	return (
		<Box className={classes.loaderCardWrap}>
			<Box className={classes.circularProgressWrap}>
				<CircularProgress />
			</Box>
			<Box className={classes.cardBody}>
				<Box className={classes.skeletonWrap}>
					<Skeleton />
				</Box>
				<Box className={classes.skeletonWrap}>
					<Skeleton />
				</Box>
				{!isMobile && (
					<Box className={classes.skeletonWrap}>
						<Skeleton height={80} />
					</Box>
				)}
			</Box>
		</Box>
	);
}
