import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	LoginCTAWrap: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: theme.palette.common.lightOrange,
		padding: theme.spacing(5),
		borderRadius: '20px',
		gap: theme.spacing(3),
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(2),
			gap: theme.spacing(2),
			flexDirection: 'column',
		},
	},
	iconAndTextSection: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(2),
	},
	iconWrap: {
		position: 'relative',
		height: '100px',
		minWidth: '100px',
		[theme.breakpoints.down('sm')]: {
			height: '70px',
			minWidth: '70px',
		},
	},
	title: {
		marginBottom: theme.spacing(2),
		...theme.typography.h4,
		fontSize: '28px',
		[theme.breakpoints.down('md')]: {
			...theme.typography.h6,
		},
	},
	subTitle: {
		fontSize: '18px',
		[theme.breakpoints.down('md')]: {
			fontSize: theme.typography.body1.fontSize,
		},
	},
	whiteBackground: {
		height: 'calc(100% - 35px)',
		width: 'calc(100% - 35px)',
		borderRadius: '50%',
		backgroundColor: theme.palette.common.white,

		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
	icon: {
		color: theme.palette.primary.main,

		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
	buttonWrap: {
		flex: 'none',
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	ctaButton: {
		paddingLeft: '30px',
		paddingRight: '30px',
	},
	textCenter: {
		textAlign: 'center',
	},
	addSpaceAfter: {
		'&::after': {
			content: '" "',
			whiteSpace: 'pre',
		},
	},
}));

export default useStyles;
