import PropTypes from 'prop-types';

// components
import LoaderCard from '@/components/shared/listingSection/partials/loaderCard';

// material
import { Box } from '@mui/material';

//style
import useStyles from './style';

export default function LoaderSection({ listCount, itemsPerRow }) {
	const { classes } = useStyles({ itemsPerRow });

	return (
		<Box className={classes.loaderListWrap}>
			{[...new Array(listCount)].map((_, index) => (
				<Box key={index} className={classes.cardWrap}>
					<LoaderCard />
				</Box>
			))}
		</Box>
	);
}

LoaderSection.propTypes = {
	listCount: PropTypes.number,
	itemsPerRow: PropTypes.object,
};
LoaderSection.defaultProps = {
	listCount: 6,
	itemsPerRow: { desktop: 4, tablet: 3 },
};
