import debounce from 'lodash/debounce';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

// constants
import {
	listTypes,
	textValues,
} from '@/components/shared/listingSection/constants';
import { titleSizes } from '@/utils/constants/titleSizes';

// actions
import { blogPageListAction } from '@/store/actions/pageActions/blogPage';
import { eventPageListAction } from '@/store/actions/pageActions/eventPage';
import { webStoryPageListAction } from '@/store/actions/pageActions/webStoryPage';

// material
import { Box } from '@mui/material';
// styles
import useStyles from './style';

// utils
import { getTagListInOrder } from './util';

// components
import ListGroup from '@/components/shared/listingSection/partials/listGroup';
import LoginCTA from '@/components/shared/loginCTA';
const TitleSection = dynamic(() => import('@/components/shared/titleSection'));
const TagSection = dynamic(() =>
	import('@/components/shared/listingSection/partials/tagSection')
);

export default function ListingSection({
	listType,
	itemCount,
	loginCTA,
	filters,
	searchQueryValues,
	...props
}) {
	const { classes } = useStyles();

	/** track ongoing API calls count, set loading false when Zero pending  */
	const pendingAPICalls = React.useRef(0);
	const [loading, setLoading] = React.useState(listType !== listTypes.v3);

	// search
	const oldSearchQueryValues = React.useRef();

	// data
	const [cardList, setCardList] = React.useState(props.cardList || []);
	const tagList = React.useMemo(
		() => getTagListInOrder(props.tags, props.categories, props.locations),
		[props.tags, props.categories, props.locations]
	);

	// filters
	// const getTagFromSearchQuery = () => {
	// 	const categoryInQuery = (
	// 		searchQueryValues.category ||
	// 		searchQueryValues.utm_category ||
	// 		''
	// 	).toLowerCase();

	// 	const categoryInParam = tagList.find(
	// 		({ name }) => name.toLowerCase() === categoryInQuery
	// 	);
	// 	if (categoryInParam) {
	// 		return { [categoryInParam.slug]: categoryInParam.name };
	// 	}

	// 	return {};
	// };
	const [selectedTagMap, setSelectedTagMap] = React.useState({});
	const [activeDayFilter, setActiveDayFilter] = React.useState(
		textValues.allEvents
	);

	// pagination
	const [page, setPage] = React.useState(1);
	const [total, setTotal] = React.useState(props.cardList.length || 0);
	const pageSize = (() => {
		if (typeof itemCount !== 'string' || itemCount.trim() === '') {
			return 6;
		}

		if (itemCount === 'all') {
			return 24;
		}

		return !isNaN(Number(itemCount)) ? Number(itemCount) : 6;
	})();

	let listAction;
	switch (listType) {
		case listTypes.v1: {
			listAction = eventPageListAction;
			break;
		}
		case listTypes.v2: {
			listAction = blogPageListAction;
			break;
		}
		case listTypes.v4: {
			listAction = webStoryPageListAction;
			break;
		}
	}

	/**
	 * click  handler for day filters: this week / this month
	 */
	const handleFilterClick = (fiterType) => {
		setPage(1);

		if (fiterType === activeDayFilter) {
			setActiveDayFilter(null);
		} else {
			setActiveDayFilter(fiterType);
		}
	};

	/**
	 * click handler for tags
	 */
	const handleTagClick = (tag) => {
		let updatedSelectedTagMap = { ...selectedTagMap };

		if (updatedSelectedTagMap[tag.slug]) {
			delete updatedSelectedTagMap[tag.slug];
		} else {
			updatedSelectedTagMap[tag.slug] = tag.name;
		}

		setPage(1);
		setSelectedTagMap(updatedSelectedTagMap);
	};

	/**
	 *  click handler for pagination arrow, increase / decrease page number
	 */
	const handlePaginationArrowClick = (pageValue) => {
		setPage(pageValue);
	};

	/**
	 *  to get Listing API arguments
	 */
	const getListActionArgs = () => {
		const args = { page, pageSize, searchQueryValues };

		switch (activeDayFilter) {
			case textValues.thisWeek:
				args.dayFilter = textValues.week;
				break;

			case textValues.thisMonth:
				args.dayFilter = textValues.month;
				break;

			case textValues.allEvents:
				args.dayFilter = textValues.all;
				break;
		}

		args.tagFilterList = Object.values(selectedTagMap);

		if (filters) {
			if (filters.countries && filters.countries.length > 0) {
				args.countries = filters.countries;
			}

			if (filters.tags && filters.tags.length > 0) {
				args.tags = filters.tags;
			}

			if (filters.categories && filters.categories.length > 0) {
				args.categories = filters.categories;
			}
		}

		return args;
	};

	// To handle custom list pagination
	React.useEffect(() => {
		if (listType !== listTypes.v3) return;

		setCardList(props.cardList.slice((page - 1) * pageSize, page * pageSize));
	}, [page]);

	// reset page and selected tag on search query change
	React.useEffect(() => {
		if (!oldSearchQueryValues.current) {
			oldSearchQueryValues.current = { ...searchQueryValues };
		}
		if (
			new URLSearchParams(searchQueryValues).toString() ===
			new URLSearchParams(oldSearchQueryValues.current).toString()
		) {
			return;
		}

		setPage(1);
		setSelectedTagMap({});
		oldSearchQueryValues.current = { ...searchQueryValues };
	}, [searchQueryValues]);

	// reset page and filters on search filters change
	React.useEffect(() => {
		setPage(1);
	}, [filters]);

	// API action call
	const debouncedListAction = React.useCallback(
		debounce((listActionArgs) => {
			if (!listAction) return;

			setLoading(true);
			++pendingAPICalls.current;

			listAction(listActionArgs)
				.then((result) => {
					if (pendingAPICalls.current !== 1) return;

					setCardList(result.data);
					setPage(result.meta.pagination.page);
					setTotal(result.meta.pagination.total);
				})
				.finally(() => {
					if (pendingAPICalls.current === 1) setLoading(false);
					--pendingAPICalls.current;
				});
		}),
		[]
	);

	React.useEffect(() => {
		const listActionArgs = getListActionArgs();
		debouncedListAction(listActionArgs);
	}, [page, selectedTagMap, activeDayFilter, filters]);

	return (
		<Box>
			{(props.title || props.highlightTitle) && (
				<Box className={classes.titleSection}>
					<TitleSection
						highlightTitle={props.highlightTitle}
						title={props.title}
						subTitle={props.subTitle}
						highlightTitleAfter={false}
						fontSize={props.titleFontSize || titleSizes.medium}
					/>
				</Box>
			)}
			{tagList.length > 0 && (
				<Box className={classes.tagSection}>
					<TagSection
						tagList={tagList}
						handleTagClick={handleTagClick}
						selectedTagMap={selectedTagMap}
					/>
				</Box>
			)}
			{loginCTA && (
				<Box className={classes.loginCTAWrap}>
					<LoginCTA
						highlightTitle={loginCTA.highlightTitle}
						title={loginCTA.title}
						subTitle={loginCTA.subTitle}
						mobileTitle={loginCTA.mobileTitle}
						mobileSubTitle={loginCTA.mobileSubTitle}
						ctaButton={loginCTA.ctaButton}
						backgroundColor={loginCTA.backgroundColor}
						formId={props.pageFormId}
						form={props.form}
						formPageConstants={props.formPageConstants}
						formPageConfig={props.formPageConfig}
						useOtpFlowForForm={props.useOtpFlowForForm}
						paymentDetail={props.paymentDetail}
						globalFormConfig={props.globalFormConfig}
						searchQueryValues={searchQueryValues}
						analyticsProps={props.analyticsProps}
					/>
				</Box>
			)}
			{(props.cardListTitle || props.cardListHighlightTitle) && (
				<Box className={classes.titleSection}>
					<TitleSection
						highlightTitle={props.cardListHighlightTitle}
						title={props.cardListTitle}
						highlightTitleAfter={true}
						fontSize={props.titleFontSize || titleSizes.medium}
					/>
				</Box>
			)}
			<Box>
				<ListGroup
					listType={listType}
					activeDayFilter={activeDayFilter}
					showDayFilter={props.showDayFilter}
					paginated={props.paginated}
					handleFilterClick={handleFilterClick}
					handlePaginationArrowClick={handlePaginationArrowClick}
					page={page}
					pageSize={pageSize}
					total={total}
					loading={loading}
					setPage={setPage}
					cardList={cardList}
					link={props.link}
				/>
			</Box>
		</Box>
	);
}

ListingSection.propTypes = {
	title: PropTypes.string,
	highlightTitle: PropTypes.string,
	titleFontSize: PropTypes.string,
	subTitle: PropTypes.string,
	listType: PropTypes.string,
	itemCount: PropTypes.string,
	showDayFilter: PropTypes.bool,
	paginated: PropTypes.bool,
	cardList: PropTypes.array,
	categories: PropTypes.array,
	tags: PropTypes.array,
	locations: PropTypes.array,
	filters: PropTypes.object,
	link: PropTypes.string,
	analyticsProps: PropTypes.object,
};
ListingSection.defaultProps = {
	listType: listTypes.v3,
	itemCount: '6',
	cardList: [],
	categories: [],
	tags: [],
	locations: [],
	filters: null,
	analyticsProps: {},
};
