import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { width, height }) => ({
	listCardWrap: {
		width: width || '100%',
		height: height || '100%',
		borderRadius: '16px',
		overflow: 'hidden',
	},
	mobileListCardWrap: {
		width: width || '100%',
		height: height || '100%',
		display: 'inline-flex',
		borderRadius: '18px',
	},
	imageWrap: {
		width: '100%',
		position: 'relative',
		paddingTop: '51.36%',
	},
	mobileImageWrap: {
		position: 'relative',
		minWidth: '90px',
		height: '90px',
		borderRadius: '12px',
		overflow: 'hidden',
	},
	cardBody: {
		backgroundColor: theme.palette.common.backgroundGrey,
		height: '100%',
		padding: theme.spacing(2),
	},
	mobileCardBody: {
		padding: theme.spacing(0, 2),
	},
	title: {
		...theme.typography.h5,
		marginBottom: theme.spacing(1),
		[theme.breakpoints.down('md')]: {
			...theme.typography.body1,
			fontWeight: theme.typography.fontWeightSemiBold,
		},
		[theme.breakpoints.down('sm')]: {
			marginBottom: 0,
		},
	},
	mobileContent: {
		fontSize: '14px',
		'& p': {
			margin: theme.spacing(0.5, 0, 0, 0),
		},
	},
}));

export default useStyles;
