// api
import { getCMSData } from '@/api';

export const webStoryPageListAction = async ({ page = 1, pageSize = 8 }) => {
	const result = await getCMSData('web-stories', {
		populate: ['image'],
		sort: ['publishedAt:desc'],
		pagination: { page, pageSize },
	});
	return result.data;
};
