import { makeStyles, withStyles } from 'tss-react/mui';

export const ctaButtonStyleWrapper = (Component) => {
	return withStyles(Component, (theme) => ({
		root: {
			borderRadius: '37px',
			color: theme.palette.common.white,
			background:
				'linear-gradient(263.25deg, rgba(245, 131, 32, 0.87) 0%, rgba(241, 105, 33, 0.987054) 81.82%, #D75312 109%)',
			boxShadow: '6.84606px 11.4101px 17.1151px rgba(208, 102, 8, 0.3)',
		},
	}));
};

const useStyles = makeStyles()((_, { shadow }) => ({
	button: shadow ? {} : { boxShadow: 'none!important' },
}));

export default useStyles;
