import Link from 'next/link';
import PropTypes from 'prop-types';

// components
import ListCard from '@/components/shared/listingSection/partials/listCard';

// material
import { Box } from '@mui/material';

// actions
import { isExternalUrl } from '@/store/actions';

//style
import useStyles from './style';

export default function OtherListSection({ cardList, ...props }) {
	const { classes } = useStyles(props);

	const renderListCard = ({ link, title, description, image }) => {
		const ListCardElement = (
			<ListCard title={title} description={description} image={image} />
		);

		return link ? (
			isExternalUrl(link) ? (
				<a href={link} target="_blank" rel="noopener noreferrer">
					{ListCardElement}
				</a>
			) : (
				<Link href={link}>
					<a>{ListCardElement}</a>
				</Link>
			)
		) : (
			ListCardElement
		);
	};

	return (
		<Box className={classes.listWrap}>
			{cardList.map((card, index) => (
				<Box key={index} className={classes.cardWrap}>
					{renderListCard(card)}
				</Box>
			))}
		</Box>
	);
}

OtherListSection.propTypes = {
	cardList: PropTypes.array,
	analyticsProps: PropTypes.object,
};
OtherListSection.defaultProps = {
	cardList: [],
	analyticsProps: {},
};
