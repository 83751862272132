import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { width, height }) => ({
	loaderCardWrap: {
		width: width || '100%',
		height: height || '100%',
		borderRadius: '16px',
		border: '1px solid #E1E1E1',
	},

	circularProgressWrap: {
		width: '100%',
		height: '200px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',

		[theme.breakpoints.down('md')]: {
			height: '100px',
		},
	},
	skeletonWrap: {
		margin: theme.spacing(2),
	},
}));

export default useStyles;
