import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { itemsPerRow = {} }) => {
	const cardWidthDesktop = `${(100 / (itemsPerRow.desktop || 4)).toFixed(2)}%`;
	const cardWidthTablet = `${(100 / (itemsPerRow.tablet || 2)).toFixed(2)}%`;
	const cardWidthLargetablet = `${(
		100 / (itemsPerRow.largeTablet || 3)
	).toFixed(2)}%`;

	return {
		loaderListWrap: {
			display: 'flex',
			flexWrap: 'wrap',
			gap: theme.spacing(2),
			[theme.breakpoints.down('sm')]: {
				justifyContent: 'center',
			},
		},
		cardWrap: {
			flexGrow: 0,
			flexShrink: 0,
			minWidth: 0,
			flexBasis: `calc(${cardWidthDesktop} - 16px)`,

			[theme.breakpoints.down('lg')]: {
				flexBasis: `calc(${cardWidthLargetablet} - 16px)`,
			},
			[theme.breakpoints.down('md')]: {
				flexBasis: `calc(${cardWidthTablet} - 16px)`,
			},
			[theme.breakpoints.down('sm')]: {
				flexBasis: '100%;',
			},
		},
	};
});

export default useStyles;
