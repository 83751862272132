/**
 * Order tags by placing offline and online at the beginning,
 * the rest of the tags are sorted alphabetically
 */
export const getTagListInOrder = (
	tags = [],
	categories = [],
	locations = []
) => {
	const tagList = [...tags, ...categories, ...locations];

	let tagAName, tagBName;
	tagList.sort((tagA, tagB) => {
		tagAName = tagA.name.toLowerCase();
		tagBName = tagB.name.toLowerCase();

		if (tagAName === 'offline') {
			return -1;
		} else if (tagBName === 'offline') {
			return 1;
		} else if (tagAName === 'online') {
			return -1;
		} else if (tagBName === 'online') {
			return 1;
		} else if (tagAName < tagBName) {
			return -1;
		} else if (tagAName > tagBName) {
			return 1;
		} else {
			return 0;
		}
	});

	return tagList;
};
